import React, { useState, useContext, useEffect } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import Header from "../../../components/site-credentials/header/Header";
import {
  AccountAssociation,
  LoyaltyCardAssociationType,
} from "../../../models/site-credentials/mobile/AccountAssociation";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { accountAssociationService } from "../../../services/site-credentials/mobile/AccountAssociationService";
import { useTranslation } from "react-i18next";
import { UserSignInResponse } from "../../../models/site-credentials/mobile/UserSignInResponse";
import SiteCredentialsBottomSheet from "../../../components/site-credentials/bottom-sheet/BottomSheet";
import WhatIsAccountMerge from "../../../components/site-credentials/what-is-account-merge/WhatIsAccountMerge";
import { DigitalAssetContext } from "../../../contexts/site-credentials/DigitalAssetContext";

import OptionsGroup from "../../../components/site-credentials/option-group/OptionsGroup";

import COLIcon from "../../../assets/images/col_cc.svg";
import AppCCIcon from "../../../assets/images/app_cc.svg";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = AccountAssociation & FormError;

export default function AccountAssociationOptions() {
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const history = useHistory();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const {
    email,
    phoneNumber,
    ccFirstName,
    ccLastName,
    ccFullName,
    colFirstName,
    colLastName,
    colFullName,
  } = location.state?.properties;

  const Form_URL = location.state != null ? location.state.nextStep : "";

  const {
    register,
    setError,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleWhatIsAccountMergeClick = () => {
    if (digitalAssetContext.digitalAsset.isMobile) {
      setBottomSheetOpen(true);
    } else {
      window.open("/continentecredentialsfaq", "_blank"); //TODO: rever o link
    }
  };

  const handleOptionClick = (option: string) => {
    switch (option) {
      case "col":
        handleMergeCOLClick();
        break;
      case "app_cc":
        handleMergeAPPClick();
        break;
    }
  };

  const handleMergeCOLClick = () => {
    submit(LoyaltyCardAssociationType.COL);
  };

  const handleMergeAPPClick = () => {
    submit(LoyaltyCardAssociationType.CC);
  };

  const submit = async (associationType: LoyaltyCardAssociationType) => {
    setLoading(true);

    try {
      const response = await accountAssociationService.accountAssociation(
        {
          associationType: associationType,
        },
        Form_URL
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), {
            ...response.value,
            responseCode: "100000",
          });
          break;
        case "error":
          if (response.code === "100013") {
            setError("submitError", {
              message: t(
                "site_credentials.email_association.account_association.invalid_code"
              ),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const accountAssociationOptionValidator = (
      register: UseFormRegister<Form>
    ) =>
      register("associationType", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /\b(CC|MERGE|COL)\b/,
          message: t(
            "site_credentials.email_association.account_association.not_correct_option"
          ),
        },
      });
    accountAssociationOptionValidator(register);
  }, [register, t]);

  return (
    <>
      <form id="AccountMerge" autoComplete="off">
        <div className="row justify-content-center">
          <Header
            title={t(
              "site_credentials.email_association.account_association.header_title"
            )}
            subtitle={t(
              "site_credentials.email_association.account_association.header_message"
            )}
            showLoading={loading}
          />
        </div>
        <div className="row justify-content-center">
          <div className="container">
            <OptionsGroup
              options={[
                {
                  text:
                    colFullName != null && colFullName != ""
                      ? t("account_association.option2") +
                        email +
                        t("account_association.option2_1") +
                        " " +
                        colFullName
                      : t("account_association.option2") + email,
                  value: "col",
                  image: () => <img src={COLIcon} alt="Continente online" />,
                },
                {
                  text:
                    ccFullName != null && ccFullName != ""
                      ? t("account_association.option1") +
                        phoneNumber +
                        t("account_association.option1_1") +
                        " " +
                        ccFullName
                      : t("account_association.option1") + phoneNumber,
                  value: "app_cc",
                  image: () => <img src={AppCCIcon} alt="App cartão" />,
                },
              ]}
              onSelect={handleOptionClick}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <span
            onClick={handleWhatIsAccountMergeClick}
            className="what-is-link"
          >
            {" "}
            {t(
              "site_credentials.email_association.account_association.what_is_fusion"
            )}{" "}
          </span>
        </div>
        <ErrorMessage error={errors.submitError} />
      </form>
      {isBottomSheetOpen && (
        <SiteCredentialsBottomSheet
          content={<WhatIsAccountMerge />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
