import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

type InputProps = {
  contextualInformationVisible?: boolean;
  footerAppsWebsitesIconsVisible?: boolean;
  contextualInformationLink?: boolean;
  message?: string;
};

export default function Footer({
  footerAppsWebsitesIconsVisible = false,
  message = "",
  contextualInformationLink = true,
  ...props
}: InputProps) {
  const continenteOnline =
    require("../../../assets/images/continente-online.svg") as string;
  const appCC = require("../../../assets/images/app-cc.svg") as string;
  const siga = require("../../../assets/images/siga.svg") as string;
  const yammi = require("../../../assets/images/yammi.svg") as string;
  const quico = require("../../../assets/images/quico.svg") as string;

  const { t } = useTranslation();
  const history = useHistory();

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const ANCHOR_WHAT_IS_SSO = "";

  const messageToShow =
    message === "" ? t("common.mobile_footer_message") : message;
  return (
    <>
      <div>
        {props.contextualInformationVisible && contextualInformationLink && (
          <div className="contextual-information-copy-2">
            <span
              className="verification-resend-code link"
              onClick={() => goToFaq(ANCHOR_WHAT_IS_SSO)}
            >
              {messageToShow}
            </span>
          </div>
        )}
        {props.contextualInformationVisible && !contextualInformationLink && (
          <div className="contextual-information-copy-2">{messageToShow}</div>
        )}
        {footerAppsWebsitesIconsVisible && (
          <div className="icon-container">
            <div className="icon-inner-container">
              <div className="icon-item">
                <img src={appCC} alt="Cartão Continente" />
                <p>{t("common.cartao_continente")}</p>
              </div>
              <div className="icon-item">
                <img src={continenteOnline} alt="Continente Online" />
                <p>{t("common.continente_online")}</p>
              </div>
              <div className="icon-item">
                <img src={siga} alt="Continente Siga" />
                <p>{t("common.continente_siga")}</p>
              </div>
              <div className="icon-item">
                <img src={yammi} alt="Yämmi" />
                <p>{t("common.yammi")}</p>
              </div>
              <div className="icon-item">
                <img src={quico} alt="Quico Continente" />
                <p>{t("common.quico")}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
