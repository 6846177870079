import React, { useState } from "react";
import { Router, Route } from "react-router-dom";
import { Switch } from "react-router";

import { createBrowserHistory, createMemoryHistory } from "history";
import i18next from "i18next";

import { Lang } from "./models/continente-credentials/common/Lang";
import {
  LangContext,
  defaultLang,
} from "./contexts/continente-credentials/LangContext";

import { DigitalAsset } from "./models/continente-credentials/common/DigitalAsset";
import {
  DigitalAssetContext,
  defaultDigitalAsset,
} from "./contexts/continente-credentials/DigitalAssetContext";

import { User } from "./models/continente-credentials/common/User";
import {
  UserContext,
  defaultUser,
} from "./contexts/continente-credentials/UserContext";

import Tests from "./pages/Tests";
import DeleteUserPage from "./pages/continente-credentials/tests/DeleteUserPage";

import SdkApp from "./SdkApp";
import SocialLoginApp from "./SocialLoginApp";
import SiteCredenciaisApp from "./SiteCredenciaisApp";

import ScrollToTop from "./ScrollToTop";

const isInIframe = window.self !== window.parent;
const history = isInIframe
  ? createMemoryHistory({
      initialEntries: [window.location.pathname + window.location.search],
    })
  : createBrowserHistory();

function changeLanguage(language?: string) {
  i18next.changeLanguage(language);
}

export default function App() {
  const [lang, setLang] = useState<Lang>(defaultLang);
  const [digitalAsset, setDigitalAsset] =
    useState<DigitalAsset>(defaultDigitalAsset);
  const [user, setUser] = useState<User>(defaultUser);

  const updateLang = (newLang: Lang) => {
    setLang(newLang);
  };

  const updateDigitalAsset = (newDigitalAsset: DigitalAsset) => {
    setDigitalAsset(newDigitalAsset);
  };

  const updateUser = (newUser: User) => {
    setUser(newUser);
  };

  const query = new URLSearchParams(window.location.search);
  const language = query.get("language") || undefined;
  changeLanguage(language);

  return (
    <Router history={history}>
      <UserContext.Provider
        value={{
          user: user,
          updateUser: updateUser,
        }}
      >
        <DigitalAssetContext.Provider
          value={{
            digitalAsset: digitalAsset,
            updateDigitalAsset: updateDigitalAsset,
          }}
        >
          <LangContext.Provider
            value={{
              lang: lang,
              updateLang: updateLang,
            }}
          >
            <ScrollToTop />
            <Switch>
              <SocialLoginApp />
            </Switch>
            <Switch>
              <SdkApp />
            </Switch>
            <Switch>
              <SiteCredenciaisApp />
            </Switch>
            {process.env.REACT_APP_NODE_ENV !== "production" && (
              <Switch>
                <Route path="/tests/delete-user" component={DeleteUserPage} />
                <Route path="/tests" component={Tests} />
              </Switch>
            )}
          </LangContext.Provider>
        </DigitalAssetContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}
