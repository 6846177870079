import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../../components/site-credentials/header/Header";
import FAQButton from "../../components/site-credentials/faq-button/faq-button";
import Option from "../../components/site-credentials/option/Option";
import Toast from "../../components/site-credentials/toast/Toast";

import { otpService } from "../../services/continente-credentials/common/OtpService";
import { getUserInformationService } from "../../services/site-credentials/mobile/GetUserInformationService";

import { RedirectResponse } from "../../models/site-credentials/mobile/RedirectResponse";

import {
  HomeRequest,
  HomeOption,
} from "../../models/site-credentials/HomeRequest";

import { Config } from "../../config/Config";
import { homeService } from "../../services/site-credentials/HomeService";
import { FormError } from "../../models/site-credentials/common/FormError";
import { divideEmail } from "../../utils/EmailDivider";
import AlertRealOtps from "../../components/alert-real-otps/alert-real-otps";

type toastElementProps = {
  id: number;
  type: string;
  message: string;
};

type Form = HomeRequest & FormError;

export default function Home() {
  const history = useHistory();
  const { t } = useTranslation();

  const ANCHOR_DISABLE_EMAIL_ASSOCIATION = "";
  const ANCHOR_DISABLE_LOYALTYCARD_ASSOCIATION = "";

  const [loading, setLoading] = useState(false);
  const [testList, setTestList] = useState<toastElementProps[]>([]);
  const [showToast, setShowToast] = useState(true);
  const [showAlertRealOtps, setShowAlertRealOtps] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const location = useLocation<RedirectResponse>();

  const [userMobileNumber, setUserMobileNumber] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userLoyaltyCardNumber, setUserloyaltyCardNumber] = useState<
    string | null
  >(null);
  const [userSelectedCountryCode, setUserSelectedCountryCode] = useState<
    string | null | undefined
  >(null);

  const goToFaq = (anchor?: string) => {
    if (anchor) {
      anchor = "#" + anchor;
      history.push("/site-credentials/faq" + anchor);
    }
    history.push("/site-credentials/faq");
  };

  const clickEditPhonenumber = () => {
    requestFormSubmit({ homeOption: HomeOption.PhoneNumber });
  };

  const clickEditEmail = () => {
    requestFormSubmit({ homeOption: HomeOption.Email });
  };

  const clickEditLoyaltyCard = () => {
    requestFormSubmit({ homeOption: HomeOption.LoyaltyCard });
  };

  const clickEditPassword = () => {
    requestFormSubmit({ homeOption: HomeOption.Password });
  };

  const requestFormSubmit = async (homeRequest: HomeRequest) => {
    setLoading(true);

    try {
      const response = await homeService.nextPage(homeRequest);
      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          updateToastMessage(t("common.unauthorized_error"), "error");
          break;
        default:
          updateToastMessage(t("common.unauthorized_error"), "error");
          break;
      }

      setLoading(false);
    } catch (e) {
      updateToastMessage(t("common.unauthorized_error"), "error");
      setLoading(false);
    }
  };

  const updateToastMessage = (message: string, type: string) => {
    const element = {
      id: 1,
      type,
      message,
    };
    setTestList([...testList, element]);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      setTestList([]);
    }, 10000);
  };

  const _handlerGetUserInformation = async () => {
    setLoading(true);

    try {
      const response = await getUserInformationService.getUserInformation(
        Config.SiteCredentials.GetUserInformationUrl
      );

      let returnValue = null;

      switch (response.kind) {
        case "success":
          returnValue = response.value;
          break;
        case "unauthorized":
          updateToastMessage(t("common.unauthorized_error"), "error");
          break;
        default:
          updateToastMessage(t("common.unexpected_error"), "error");
          break;
      }

      setLoading(false);
      return returnValue;
    } catch (e) {
      updateToastMessage(t("common.unexpected_error"), "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state != null && location.state.responseCode != null) {
      let message = "";
      let type = "";
      switch (location.state.responseCode) {
        case "100000": {
          message = "site_credentials.association_success";
          type = "success";
          break;
        }
        case "100001": {
          message = "site_credentials.update_password.success_message";
          type = "success";
          break;
        }
        case "100002": {
          message =
            "site_credentials.loyaltycard_association.card_creation_success";
          type = "success";
          break;
        }
        case "100003": {
          message =
            "site_credentials.loyaltycard_association.card_association_success";
          type = "success";
          break;
        }
        case "300009": {
          message =
            "site_credentials.email_association.email_already_registered";
          type = "error";
          break;
        }
      }

      if (message != null) {
        updateToastMessage(t(message), type);
      }
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userInformation = await _handlerGetUserInformation();

      if (userInformation) {
        setUserMobileNumber(userInformation.usernamePhoneNumber);

        setUserEmail(userInformation.usernameEmail);
        setUserloyaltyCardNumber(userInformation.loyaltyCardNumber);
      }

      setIsPageLoaded(true);

      if (process.env.REACT_APP_NODE_ENV !== "production") {
        const useFakeOTPResult = await otpService.useFakeOTP();

        if (useFakeOTPResult.kind === "success") {
          if (
            useFakeOTPResult.value.isUsingFakeOtp != null &&
            !useFakeOTPResult.value?.isUsingFakeOtp
          ) {
            showWillSendRealOtps();
          }
        }
      }
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showWillSendRealOtps = () => {
    setShowAlertRealOtps(true);

    setTimeout(() => {
      setShowAlertRealOtps(false);
    }, 3000);
  };

  return (
    <>
      <div className="row justify-content-center">
        {showToast && <Toast toastList={testList} />}
        {showAlertRealOtps && (
          <AlertRealOtps
            type={"error"}
            message={"Alerta: envio de SMS e Email ativo!"}
          />
        )}
        <Header showLoading={loading} />
        {isPageLoaded && (
          <div>
            <div className={"sc-section-title"}>
              <p>{t("site_credentials.credentials")}</p>
            </div>
            <div className="sc-full-container">
              <Option
                labelText={t("site_credentials.mobile_number")}
                informationText={userMobileNumber}
                onClick={clickEditPhonenumber}
                iconType={"editable"}
                type={"tel"}
                selectedCountryCode={userSelectedCountryCode}
              />
              <Option
                labelText={t("site_credentials.email")}
                informationText={divideEmail(userEmail)}
                // onClick={
                //   userEmail
                //     ? () => goToFaq(ANCHOR_DISABLE_EMAIL_ASSOCIATION)
                //     : clickEditEmail
                // }
                onClick={clickEditEmail}
                iconType={userEmail ? "readonly" : "editable"}
              />
              <Option
                labelText={t("site_credentials.pw")}
                onClick={clickEditPassword}
                iconType={"editable"}
              />
            </div>
            <div className={"sc-section-title"}>
              <p>{t("site_credentials.continente_card")}</p>
            </div>
            <div className="sc-full-container c-pointer">
              <Option
                labelText={t("site_credentials.continente_card")}
                informationText={userLoyaltyCardNumber}
                onClick={
                  userLoyaltyCardNumber
                    ? () => goToFaq(ANCHOR_DISABLE_LOYALTYCARD_ASSOCIATION)
                    : clickEditLoyaltyCard
                }
                iconType={userLoyaltyCardNumber ? "readonly" : "editable"}
              />
            </div>
          </div>
        )}
        <FAQButton
          onClick={() => goToFaq(ANCHOR_DISABLE_LOYALTYCARD_ASSOCIATION)}
        />
      </div>
      {/* </form> */}
    </>
  );
}
