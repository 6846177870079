import React, {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
  useCallback,
} from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import UsernameInput from "../../../components/continente-credentials/username-input/UsernameInput";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../components/continente-credentials/Form/Form";
import CredentialsInput from "../../../components/continente-credentials/input/Input";
import { CardCreation } from "../../../models/continente-credentials/mobile/CardCreation";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/continente-credentials/UserContext";
import { LangContext } from "../../../contexts/continente-credentials/LangContext";
import { DigitalAssetContext } from "../../../contexts/continente-credentials/DigitalAssetContext";
import { cardCreationService } from "../../../services/continente-credentials/mobile/CardCreationService";
import { PhoneNumberAssociationResponse } from "../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import { validatePortuguesePhoneNumber } from "../../../utils/validatePortuguesePhoneNumber";
import MobileDatePicker from "../../../components/continente-credentials/mobile-date-picker/mobile-date-picker";
import DesktopDatePicker from "../../../components/continente-credentials/desktop-date-picker/desktop-date-picker";
import Checkbox from "../../../components/continente-credentials/checkbox/Checkbox";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

type Form = CardCreation & FormError;

export default function LoyaltyCardCreation() {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberAssociationResponse>();
  const [dateLocation, setDateLocation] = useState("pt-PT");
  const [labelNameVisible, setLabelNameVisible] = useState<boolean>(false);
  const [labelEmailVisible, setLabelEmailVisible] = useState<boolean>(false);
  const [labelPhoneNumberVisible] = useState<boolean>(true);
  const [labelBirthdateVisible, setLabelBirthdateVisible] =
    useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [isEmailAlreadySet, setIsEmailAlreadySet] = useState<boolean>(false);
  const [isPhoneNumberAlreadySet, setIsPhoneNumberAlreadySet] =
    useState<boolean>(false);

  const [countryCode, setCountryCode] = useState("");
  const [birthdate, setBirthdate] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const langContext = useContext(LangContext);
  const digitalAssetContext = useContext(DigitalAssetContext);

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [time, setTime] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkGeneralConditions, setCheckGeneralConditions] = useState(false);

  const ALLOWED_AGE_TO_CREATE_CARD = 16;

  const handleClick = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleSelect = (e: any) => {
    clearErrors();

    setTime(e);
    setIsOpen(false);
    setBirthdate(e.toLocaleDateString(dateLocation));
    setLabelBirthdateVisible(
      e.toLocaleDateString(dateLocation).length > 0 ? true : false
    );
    setValue("birthdate", e.toLocaleDateString(dateLocation).trim());
  };

  const handleDesktopDatePickerSelect = (e: any) => {
    clearErrors();
    setBirthdate(e.toLocaleDateString(dateLocation));
    setLabelBirthdateVisible(
      e.toLocaleDateString(dateLocation).length > 0 ? true : false
    );
    setValue("birthdate", e.toLocaleDateString(dateLocation).trim());
    setStartDate(e);
  };

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const GENERAL_CONDITIONS = "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handlePhoneNumberChange = useCallback(
    (input: string, phoneNumberCountryCode: string) => {
      setCountryCode(phoneNumberCountryCode);
      setPhoneNumber(input?.trim());
      clearErrors();
      setValue(
        "phoneNumber",
        input?.trim() !== "" ? `${phoneNumberCountryCode}${input}`.trim() : ""
      );
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    clearErrors();
    if (
      (userContext?.user?.email !== null &&
        userContext?.user?.email?.trim() !== "") ||
      (location?.state?.properties?.email !== null &&
        location?.state?.properties?.email?.trim() !== "")
    ) {
      const emailAux =
        userContext?.user?.email?.trim() !== ""
          ? userContext?.user?.email?.trim()
          : location?.state?.properties?.email?.trim();
      setValue("email", emailAux);
      setEmail(emailAux);

      setIsEmailAlreadySet(true);
    }

    if (
      (userContext.user?.phoneNumber !== null &&
        userContext.user?.phoneNumber?.trim() !== "") ||
      (location?.state?.properties?.phoneNumber !== null &&
        location?.state?.properties?.phoneNumber?.trim() !== "")
    ) {
      setIsPhoneNumberAlreadySet(true);
    }

    setLabelEmailVisible(
      userContext?.user?.email?.trim().length > 0 ||
        location?.state?.properties?.email?.trim().length > 0
    );

    switch (langContext.lang.code) {
      case "pt":
        setDateLocation("pt-PT");
        break;
    }

    handlePhoneNumberChange(
      userContext.user?.phoneNumber?.trim() === ""
        ? location.state.properties?.phoneNumber
        : userContext.user.phoneNumber?.trim(),
      userContext.user?.phoneNumberCode === ""
        ? location.state.properties?.phoneCountryCode ?? ""
        : userContext.user?.phoneNumberCode
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("name", value.trim());
    setName(value);
    setLabelNameVisible(value.length > 0 ? true : false);
  };

  const handleEmailChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("email", value.trim());
    setEmail(value);
    setLabelEmailVisible(value.length > 0 ? true : false);
  };

  const handleCheckGeneralConditionsChange = () => {
    setValue("checkGeneralConditions", !checkGeneralConditions);
    setCheckGeneralConditions((state) => {
      return !state;
    });
  };

  const onSubmit: SubmitHandler<CardCreation> = async (data) => {
    setLoading(true);

    try {
      const response = await cardCreationService.cardCreation(
        {
          birthdate: data.birthdate,
          email: data.email,
          name: data.name,
          countryCode: location.state.properties.phoneCountryCode
            ? location.state.properties.phoneCountryCode
            : countryCode,
          phoneNumber: phoneNumber,
          checkGeneralConditions: checkGeneralConditions,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          //   response.value.numberOfStepsToAssociationStep =
          //     numberOfStepsToAssociationStep;
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        case "error":
          if (response.code === "100013") {
            setError("submitError", {
              message: t("card_association.invalid_code"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const nameValidator = (register: UseFormRegister<Form>) =>
      register("name", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^([a-zA-ZÀ-ú']{2,40}(\s+|$)){2,}$/,
          message: t("add_card.invalid_name"),
        },
      });
    const emailValidator = (register: UseFormRegister<Form>) =>
      register("email", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: t("add_card.invalid_email"),
        },
      });
    const phoneNumberValidator = (register: UseFormRegister<Form>) =>
      register("phoneNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^\+?\d*$/,
          message: t("add_card.invalid_phonenumber"),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    const birthdateValidator = (register: UseFormRegister<Form>) =>
      register("birthdate", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/,
          message: "",
        },
        validate: {
          yearsOld: (v) =>
            getAge(v) >= ALLOWED_AGE_TO_CREATE_CARD ||
            t("add_card.invalid_age"),
        },
      });

    const getAge = (inputBirthdate: string) => {
      const day = parseInt(inputBirthdate.split("/")[0]);
      const month = parseInt(inputBirthdate.split("/")[1]) - 1;
      const year = parseInt(inputBirthdate.split("/")[2]);

      const today = new Date();
      const birthDate = new Date(year, month, day);

      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return Number.isInteger(age) ? age : 0;
    };

    const checkGeneralConditionsValidator = (register: UseFormRegister<Form>) =>
      register("checkGeneralConditions", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    nameValidator(register);
    emailValidator(register);
    phoneNumberValidator(register);
    birthdateValidator(register);
    checkGeneralConditionsValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="ContinenteCardCreation"
        form={formState}
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("add_card.header_title")}
            subtitle={t("add_card.header_message")}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <CredentialsInput
            labelText={t("add_card.name_input")}
            isFilled={labelNameVisible}
            isError={errors.name ? true : false}
            errorMsg={errors.name ? errors.name.message : ""}
            onChange={handleNameChange}
            value={name}
          />
          <CredentialsInput
            labelText={t("add_card.email_input")}
            isFilled={labelEmailVisible}
            isError={errors.email ? true : false}
            errorMsg={errors.email ? errors.email.message : ""}
            disabled={isEmailAlreadySet}
            onChange={handleEmailChange}
            value={email}
            autoComplete="on"
          />
          <div className="container-input margin-bottom-12">
            <UsernameInput
              type="phone"
              isFilled={labelPhoneNumberVisible}
              isError={errors.phoneNumber ? true : false}
              errorMsg={errors.phoneNumber ? errors.phoneNumber.message : ""}
              onChange={handlePhoneNumberChange}
              disabled={isPhoneNumberAlreadySet}
              autoComplete="off"
              value={phoneNumber}
              defaultValue={
                phoneNumber === ""
                  ? location.state.properties.phoneNumber
                  : phoneNumber
              }
              defaultPhoneCountryCode={
                location.state.properties.phoneCountryCode === "" ||
                location.state.properties.phoneCountryCode == null
                  ? "+351"
                  : location.state.properties.phoneCountryCode
              }
            />
          </div>
          {digitalAssetContext.digitalAsset.isMobile ? (
            <MobileDatePicker
              labelText={t("add_card.dob_input")}
              isFilled={labelBirthdateVisible}
              isError={errors.birthdate ? true : false}
              errorMsg={errors.birthdate ? errors.birthdate.message : ""}
              value={birthdate}
              confirmText={t("add_card.confirm")}
              cancelText={t("add_card.cancel")}
              onSelect={handleSelect}
              onCancel={handleCancel}
              onClick={handleClick}
              isOpen={isOpen}
              time={time}
            />
          ) : (
            <DesktopDatePicker
              labelText={t("add_card.dob_input")}
              isError={errors.birthdate ? true : false}
              errorMsg={errors.birthdate ? errors.birthdate.message : ""}
              value={startDate}
              onChange={handleDesktopDatePickerSelect}
              lang={langContext.lang.code}
            />
          )}
        </div>

        <Checkbox
          onClick={handleCheckGeneralConditionsChange}
          isError={errors.checkGeneralConditions ? true : false}
          label={
            <>
              {t("common.check_general_conditions_message1")}{" "}
              <span
                onClick={() => goToFaq(GENERAL_CONDITIONS)}
                className="button-as-link"
              >
                <b>{t("common.check_general_conditions_message2")}</b>
              </span>
            </>
          }
        />
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("add_card.button_next_message")}
            type="submit"
            isDisabled={checkGeneralConditions ? false : true}
          />
        </div>
      </CredentialsForm>
    </>
  );
}
