import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../components/continente-credentials/Form/Form";
import CredentialsPROtpInput from "../../../components/continente-credentials/password-recover-otp-input/password-recover-otp-input";
import { PasswordRecoverVerifyOtp } from "../../../models/continente-credentials/mobile/PasswordRecoverVerifyOtp";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { useTranslation } from "react-i18next";
import { passwordRecoverService } from "../../../services/continente-credentials/mobile/PasswordRecoverService";
import { PasswordRecoverValidateIdentifierResponse } from "../../../models/continente-credentials/mobile/PasswordRecoverValidateIdentifierResponse";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

type Form = PasswordRecoverVerifyOtp & FormError;

export default function VerifyOtpEmail() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PasswordRecoverValidateIdentifierResponse>();
  const history = useHistory();
  const identifier =
    location.state != null ? location.state.properties.identifier : "";
  const jwtToken =
    location.state != null ? location.state.properties.jwtToken : "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handleOTPChange = (value: string) => {
    clearErrors();
    setValue("code", value.trim());
  };

  const onSubmit: SubmitHandler<PasswordRecoverVerifyOtp> = async (data) => {
    setLoading(true);
    try {
      const response = await passwordRecoverService.otpValidation(
        {
          code: data.code,
          jwtToken: jwtToken,
          identifier: identifier,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          //response.value.numberOfStepsToAssociationStep = numberOfStepsToAssociationStep;
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        case "error":
          if (response.code === "100004") {
            setError("code", {
              message: t("password_recover.invalid_code"),
            });
            break;
          } else if (response.code === "100015") {
            setError("submitError", {
              message: t("password_recover.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("password_recover.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const otpValidator = (register: UseFormRegister<Form>) =>
      register("code", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("password_recover.code_must_be_numeric"),
        },
      });
    otpValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="VerifyOtpEmail"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("password_recover.header_otp_title")}
            subtitle={
              t("password_recover.header_otp_email_message") +
              " " +
              location.state.properties.identifier
            }
            showLoading={loading}
            previousStepBackXTimes={2}
          />
        </div>
        <div className="row justify-content-center">
          <CredentialsPROtpInput
            isError={errors.code ? true : false}
            errorMsg={errors.code ? errors.code.message : ""}
            onChange={handleOTPChange}
            phoneNumber={identifier}
            jwtToken={jwtToken}
            onResendClick={setLoading}
          />
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("password_recover.button_next_message")}
            type="submit"
          />
        </div>
        <div className="d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
