import React, { ChangeEvent, useState } from "react";

import check from "../../../assets/images/check2-min.svg";
import alertCircle from "../../../assets/images/alert-circle.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  type?: "password" | "text";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  showCheckIcon?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  isDateInput?: boolean;
  showError?: boolean;
  autoComplete?: string;
};

export default function Input({ showError = true, ...props }: InputProps) {
  const [isHidden, setIsHidden] = useState<boolean>(props.type === "password");

  const clickEye = () => {
    setIsHidden((state) => {
      return !state;
    });
  };

  return (
    <>
      <div className={`container-input ${showError ? "margin-bottom-12" : ""}`}>
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          type={!isHidden ? "text" : props.type}
          className="input-fields"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          onClick={props.onClick}
          onKeyPress={props.onKeyPress}
          autoComplete={props.autoComplete}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (props.isDateInput) {
              e.preventDefault();
            }
          }}
        />
        <label className={`label-input ${props.isFilled ? "filled" : ""}`}>
          {props.labelText}
        </label>
        {showError && (
          <div className="d-inline-flex align-items-center errorDiv">
            {props.isError && (
              <img src={alertCircle} alt="" className="alert-cicle" />
            )}
            <span className="errorLabel">{props.errorMsg}</span>
          </div>
        )}
        {props.type === "password" && isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEye} onClick={clickEye} />
          </div>
        )}
        {props.type === "password" && !isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEyeSlash} onClick={clickEye} />
          </div>
        )}
        {props.showCheckIcon && (
          <img src={check} alt="" className="input-check" />
        )}
      </div>
    </>
  );
}
