import React from "react";

type Props = {
  type: string;
  message: string;
};

export default function AlertRealOtps({ ...props }: Props) {
  return (
    <>
      <div className={`alert-real-otps-container ${props.type} `}>
        <label className={`alert-real-otps-text ${props.type}`} htmlFor="">
          {props.message}
        </label>
      </div>
    </>
  );
}
