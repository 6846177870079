import { useEffect } from "react";

import { RecaptchaState } from "../../models/continente-credentials/mobile/RecaptchaState";
import { Config } from "../../config/Config";
import i18next from "i18next";

export const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

window.googleCallback = () => {
  if (!document.getElementById("g-recaptcha")?.hasChildNodes()) {
    window.grecaptcha.render("g-recaptcha", {
      sitekey: siteKey,
      callback: "googleChallengeSuccess",
    });
  }
};

window.googleChallengeSuccess = () => {
  window.dispatchEvent(
    new CustomEvent("googleChallenge", { detail: "successful_chalenge" })
  );
};

export function useGoogleRecaptcha(
  locationState: RecaptchaState | null,
  successCallbackFunction: EventListener | null = null
) {
  useEffect(() => {
    if (locationState?.properties.requiredRecaptcha) {
      let language = "";

      switch (i18next.language) {
        case "pt":
          language = "&hl=pt";
          break;
        case "en":
          language = "&hl=en";
          break;
        default:
          language = "&hl=pt";
      }

      const script = document.createElement("script");
      script.src = Config.GoogleRecaptcha.Url + language;
      document.body.appendChild(script);
      var styleElement = document.createElement("style");
      styleElement.id = "recaptcha-style";

      // Define your CSS rules
      var cssRules = `
        #g-recaptcha> :first-child {
          padding: 0;
        }
        
        #rc-imageselect {
          transform: scale(0.77);
          -webkit-transform: scale(0.77);
          transform-origin: 0 0;
          -webkit-transform-origin: 0 0;
        }

        body > :last-child {
          left: 0 !important;
          position: absolute !important;
          left: 0 !important;
          right: 0 !important;
          margin-left: auto !important;
          margin-right: auto !important;
          background-color: transparent !important;
          border: none !important;
          box-shadow: none !important;
        }

        body > :last-child > :last-child {
          display: flex !important;
          justify-content: center !important;
          margin-left: auto !important;
          margin-right: auto !important;
          background-color: white !important;
          border: 1px solid rgb(204, 204, 204);
          box-shadow: 10px 10px 10px rgb(230 230 230);
        }
      `;

      // Append CSS rules to the <style> element
      styleElement.appendChild(document.createTextNode(cssRules));

      // Append the <style> element to the <head> of the document
      document.head.appendChild(styleElement);
    }

    if (successCallbackFunction != null) {
      window.addEventListener("googleChallenge", successCallbackFunction);
    }
    return () => {
      document.getElementById("recaptcha-style")?.remove();

      if (successCallbackFunction != null) {
        window.removeEventListener("googleChallenge", successCallbackFunction);
      }
    };
  }, [locationState, successCallbackFunction]);
}

export function getRecaptchaToken(
  locationState: RecaptchaState | null
): string | undefined {
  if (locationState?.properties.requiredRecaptcha)
    try {
      return window.grecaptcha.getResponse();
    } catch (e) {
      return undefined;
    }

  return undefined;
}
