import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/site-credentials/header/Header";
import Footer from "../../../components/site-credentials/footer/Footer";
import { EmailDefinition } from "../../../models/site-credentials/mobile/EmailDefinition";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UsernameInput from "../../../components/site-credentials/username-input/UsernameInput";
import { emailDefinitionService } from "../../../services/site-credentials/mobile/EmailDefinitionService";
import { EmailDefinitionResponse } from "../../../models/site-credentials/mobile/EmailDefinitionResponse";
import { useLocation, useHistory } from "react-router-dom";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = EmailDefinition & FormError;

export default function SiteCredenciaisEmailDefinition() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation<EmailDefinitionResponse>();
  const [labelEmailVisible] = useState<boolean>(true);

  const INVALID_INPUT_ERROR_MESSAGE =
    "site_credentials.email_association.definition.invalid_input";

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handleEmailChange = useCallback(
    (input: string) => {
      clearErrors();
      setValue("email", input.replace(/\s+/g, ""));
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    const emailValidator = (register: UseFormRegister<Form>) =>
      register("email", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value:
            /(^\+?\d*$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*$)/,
          message: t(INVALID_INPUT_ERROR_MESSAGE),
        },
      });
    emailValidator(register);
  }, [register, t]);

  const onSubmit: SubmitHandler<EmailDefinition> = async (data) => {
    setLoading(true);

    try {
      if (
        location.state != null &&
        !location.state.nextStep.length &&
        !location.state.properties.email.length
      ) {
        throw new Error(
          "Location State does not have all the necessary fields."
        );
      }

      const response = await emailDefinitionService.EmailDefinition(
        {
          email: data.email,
        },
        location.state.nextStep
      );

      if (response.kind == "success") {
        history.push(response.value.nextStep.substring(4), response.value);
      } else if (response.kind == "error") {
        switch (response.code) {
          case "300137":
            setError("submitError", {
              message: t(
                "site_credentials.email_definition.credential_already_completed_error"
              ),
            });
            break;
          case "300138":
            setError("submitError", {
              message: t(
                "site_credentials.email_definition.credential_already_exists_error"
              ),
            });
            break;
          default:
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
        }
      } else {
        setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  return (
    <>
      <form
        id="EmailDefinition"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row d-flex justify-content-center input-padding-24">
          <div className="div-content">
            <div className="row justify-content-center">
              <Header
                title={t(
                  "site_credentials.email_association.definition.header_title"
                )}
                subtitle={t(
                  "site_credentials.email_association.definition.header_message"
                )}
                showLoading={loading}
                previousStepBackXTimes={2}
              />
            </div>
            <div className="row d-flex justify-content-center input-padding-24">
              <UsernameInput
                type="email-phone"
                isFilled={labelEmailVisible}
                isError={errors.email ? true : false}
                errorMsg={errors.email ? errors.email.message : ""}
                onChange={handleEmailChange}
                value={getValues("email")}
              />
            </div>
            <ErrorMessage error={errors.submitError} />
          </div>

          <div className="row d-flex justify-content-center input-padding-24">
            <Footer />
          </div>
        </div>
      </form>
    </>
  );
}
