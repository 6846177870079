import { useLocation } from "react-router";

import { AuthorizeSRTQueryParams } from "../../models/continente-credentials/common/AuthorizeSRTQueryParams";

export const useAuthorizeSRTQueryParams = (): AuthorizeSRTQueryParams => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  return {
    clientId: urlSearchParams.get("clientId"),
    sessionId: urlSearchParams.get("sessionId"),
    srToken: urlSearchParams.get("srToken"),
  };
};
