import React, { useEffect, useState, useContext, useCallback } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import UsernameInput from "../../../../components/continente-credentials/username-input/UsernameInput";
import BottomSheet from "../../../../components/continente-credentials/bottom-sheet/BottomSheet";
import ContinenteCredentialsFaq from "../../../../components/continente-credentials/continente-credentials-faq/ContinenteCredentialsFaq";

import { UserSignInResponse } from "../../../../models/continente-credentials/mobile/UserSignInResponse";
import { PhoneNumberAssociation } from "../../../../models/continente-credentials/mobile/PhoneNumberAssociation";
import { FormError } from "../../../../models/continente-credentials/common/FormError";

import { phoneNumberAssociationService } from "../../../../services/continente-credentials/mobile/PhoneNumberAssociationService";
import { useTranslation } from "react-i18next";
import { DigitalAssetContext } from "../../../../contexts/continente-credentials/DigitalAssetContext";

import { UserContext } from "../../../../contexts/continente-credentials/UserContext";

import { validatePortuguesePhoneNumber } from "../../../../utils/validatePortuguesePhoneNumber";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";

import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../../../hooks/continente-credentials/useGoogleRecaptcha";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";

type Form = PhoneNumberAssociation & FormError;

export default function User() {
  const history = useHistory();

  const [labelPhoneNumberVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const [phoneNumber, setPhoneNumber] = useState(
    location.state != null ? location.state.properties.phoneNumber : ""
  );
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [countryCode, setCountryCode] = useState(
    location.state != null
      ? location.state.properties.phoneNumberCountryCode ?? ""
      : ""
  );
  useGoogleRecaptcha(location.state);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const userContext = useContext(UserContext);

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handlePhoneNumberChange = useCallback(
    (input: string, phoneNumberCountryCode: string) => {
      clearErrors();

      const sanitizedInput = input.replace(/\s+/g, "");
      const sanitizedCountryCode = phoneNumberCountryCode.replace(/\s+/g, "");

      setCountryCode(sanitizedCountryCode);
      setPhoneNumber(sanitizedInput);

      const formattedPhoneNumber =
        sanitizedInput !== "" ? `${sanitizedCountryCode}${sanitizedInput}` : "";

      setValue("phoneNumber", formattedPhoneNumber);
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    let trimPhone = phoneNumber?.trim();
    let trimCountryCode = countryCode?.trim();

    if (trimPhone === undefined || trimPhone === null || trimPhone === "") {
      trimPhone = userContext.user.phoneNumber.trim();
    }

    if (
      trimCountryCode === undefined ||
      trimCountryCode === null ||
      trimCountryCode === ""
    ) {
      trimCountryCode = userContext.user.phoneNumberCode.trim();
    }
    handlePhoneNumberChange(trimPhone, trimCountryCode);
    setValue("phoneNumber", `${trimCountryCode}${trimPhone}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skipAssociation = async () => {
    setLoading(true);
    try {
      if (
        location.state != null &&
        !location.state.nextStep.length &&
        !location.state.properties.email.length
      ) {
        throw new Error(
          "Location State does not have all the necessary fields."
        );
      }
      const response =
        await phoneNumberAssociationService.PhoneNumberAssociation(
          {
            phoneNumber: "",
            email: location.state.properties.email,
            skipAssociation: true,
          },
          location.state.nextStep
        );

      switch (response.kind) {
        case "success":
          userContext.updateUser({
            ...userContext.user,
            phoneNumber: phoneNumber.trim(),
            phoneNumberCode: countryCode,
            skippedPhoneNumberAssociation: true,
          });
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }
      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<PhoneNumberAssociation> = async (data) => {
    setLoading(true);
    try {
      if (
        location.state != null &&
        !location.state.nextStep.length &&
        !location.state.properties.email.length
      ) {
        throw new Error(
          "Location State does not have all the necessary fields."
        );
      }

      const response =
        await phoneNumberAssociationService.PhoneNumberAssociation(
          {
            phoneNumber: data.phoneNumber,
            email: location.state.properties.email,
            skipAssociation: false,
            phoneCountryCode: countryCode,
            simplePhoneNumber: phoneNumber,
            recaptchaToken: getRecaptchaToken(location.state),
          },
          location.state.nextStep
        );

      switch (response.kind) {
        case "success":
          userContext.updateUser({
            ...userContext.user,
            phoneNumber: phoneNumber.trim(),
            phoneNumberCode: countryCode,
            skippedPhoneNumberAssociation: false,
          });
          history.push(response.value.nextStep.substring(4), response.value);

          break;
        case "error":
          if (response.code === "300137") {
            setError("submitError", {
              message: t("phonenumber_association.invalid_association"),
            });
          } else {
            setError("submitError", { message: t("common.unexpected_error") });
          }
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const phoneNumberValidator = (register: UseFormRegister<Form>) =>
      register("phoneNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^\+?\d*$/,
          message: t("phonenumber_association.invalid_phonenumber"),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    phoneNumberValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        form={formState}
        id="PhoneNumberAssociation"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("phonenumber_association.header_title")}
            subtitle={t("phonenumber_association.header_message")}
            showLoading={loading}
            backClickToPushPath={
              "/user-register?clientId=" +
              digitalAssetContext.digitalAsset.clientId
            }
          />
        </div>
        <div className="row d-flex justify-content-center input-padding-24">
          <UsernameInput
            type="phone"
            isFilled={labelPhoneNumberVisible}
            isError={errors.phoneNumber ? true : false}
            errorMsg={errors.phoneNumber ? errors.phoneNumber.message : ""}
            onChange={handlePhoneNumberChange}
            value={phoneNumber}
            defaultPhoneCountryCode={
              countryCode !== ""
                ? countryCode
                : UsernameInput.defaultProps.defaultPhoneCountryCode
            }
          />
        </div>
        <div
          id="g-recaptcha"
          className="row d-flex justify-content-center input-padding-24"
          data-sitekey={siteKey}
        />
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("phonenumber_association.button_next_message")}
            type="submit"
          />
        </div>
        <div className="row d-flex justify-content-center">
          {digitalAssetContext.digitalAsset
            .requiredPhoneNumberAssociation ? undefined : (
            <MantraButton
              text={t("phonenumber_association.jump_next_button_message")}
              type="button"
              color="secondary"
              onClick={skipAssociation}
            />
          )}
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<ContinenteCredentialsFaq />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
