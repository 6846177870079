import React from "react";

import { DigitalAsset } from "../../models/continente-credentials/common/DigitalAsset";

export const defaultDigitalAsset: DigitalAsset = {
  name: "",
  clientId: "",
  baseUrl: "",
  isMobile: true,
  requiredPhoneNumberAssociation: true,
  requiredEmailAssociation: true,
  requiredCardAssociation: true,
  showCloseButton: false,
  showSiteCredentialsLink: false,
};

export const DigitalAssetContext = React.createContext({
  digitalAsset: defaultDigitalAsset,
  updateDigitalAsset: (digitalAsset: DigitalAsset) => {},
});
