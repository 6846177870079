import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/site-credentials/header/Header";
import Footer from "../../../components/site-credentials/footer/Footer";
import { PhoneNumberDefinition } from "../../../models/site-credentials/mobile/PhoneNumberDefinition";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UsernameInput from "../../../components/site-credentials/username-input/UsernameInput";
import { phoneNumberDefinitionService } from "../../../services/site-credentials/mobile/PhoneNumberDefinitionService";
import { PhoneNumberDefinitionResponse } from "../../../models/site-credentials/mobile/PhoneNumberDefinitionResponse";
import { useLocation, useHistory } from "react-router-dom";
import { validatePortuguesePhoneNumber } from "../../../utils/validatePortuguesePhoneNumber";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../../hooks/continente-credentials/useGoogleRecaptcha";

type Form = PhoneNumberDefinition & FormError;

export default function SiteCredenciaisPhoneNumberDefinition() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation<PhoneNumberDefinitionResponse>();
  const [labelPhoneNumberVisible] = useState<boolean>(true);
  const [countryCode, setCountryCode] = useState("+351");
  const [simplePhoneNumber, setSimplePhoneNumber] = useState("");

  useGoogleRecaptcha(location.state);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const INVALID_INPUT_ERROR_MESSAGE =
    "site_credentials.phonenumber_association.definition.invalid_input";
  const PHONENUMBER_INVALID_IS_THE_SAME =
    "site_credentials.phonenumber_association.definition.invalid_input_same_number";
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handlePhoneNumberChange = useCallback(
    (input: string, phoneNumberCountryCode: string) => {
      const sanitizedInput = input.replace(/\s+/g, "");

      setCountryCode(phoneNumberCountryCode);
      setSimplePhoneNumber(sanitizedInput);

      clearErrors();

      const formattedPhoneNumber = sanitizedInput
        ? `${phoneNumberCountryCode}${sanitizedInput}`
        : "";

      setValue("phoneNumber", formattedPhoneNumber);
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    const phoneNumberValidator = (register: UseFormRegister<Form>) =>
      register("phoneNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^\+?\d*$/,
          message: t(INVALID_INPUT_ERROR_MESSAGE),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    phoneNumberValidator(register);
  }, [register, t]);

  const onSubmit: SubmitHandler<PhoneNumberDefinition> = async (data) => {
    setLoading(true);

    try {
      if (
        location.state != null &&
        !location.state.nextStep.length &&
        !location.state.properties.usernamePhoneNumber.length
      ) {
        throw new Error(
          "Location State does not have all the necessary fields."
        );
      }

      let body = {
        phoneNumber: simplePhoneNumber,
        phoneNumberCountryCode: countryCode,
        usernamePhoneNumber: data.phoneNumber,
        recaptchaToken: getRecaptchaToken(location.state),
        requiredRecaptcha: location.state.properties.requiredRecaptcha,
      };

      const response = await phoneNumberDefinitionService.PhoneNumberDefinition(
        body,
        location.state.nextStep
      );

      switch (response.kind) {
        case "success":
          response.value.properties.countryCode = countryCode;
          response.value.properties.phoneNumber = simplePhoneNumber;
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          if (response.code === "300137") {
            setError("submitError", {
              message: t(INVALID_INPUT_ERROR_MESSAGE),
            });
          } else if (response.code === "100000") {
            setError("submitError", {
              message: t(PHONENUMBER_INVALID_IS_THE_SAME),
            });
          } else if (response.code === "100028") {
            setError("submitError", {
              message: t(
                "site_credentials.phonenumber_association.definition.invalid_google_recaptcha_token"
              ),
            });
          } else {
            setError("submitError", { message: t("common.unexpected_error") });
          }
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  return (
    <>
      <form
        id="PhoneNumberDefinition"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row d-flex justify-content-center input-padding-24">
          <div className="div-content">
            <div className="row justify-content-center">
              <Header
                title={t(
                  "site_credentials.phonenumber_association.definition.header_title"
                )}
                subtitle={t(
                  "site_credentials.phonenumber_association.definition.header_message"
                )}
                showLoading={loading}
                backClickToPushPath={"/site-credentials"}
              />
            </div>
            <div className="row d-flex justify-content-center input-padding-24">
              <UsernameInput
                type="phone"
                isFilled={labelPhoneNumberVisible}
                isError={errors.phoneNumber ? true : false}
                errorMsg={errors.phoneNumber ? errors.phoneNumber.message : ""}
                onChange={handlePhoneNumberChange}
                value={simplePhoneNumber}
                defaultPhoneCountryCode={countryCode}
              />
              <div
                id="g-recaptcha"
                className="row d-flex justify-content-center input-padding-24"
                data-sitekey={siteKey}
              />
            </div>
            <ErrorMessage error={errors.submitError} />
          </div>
          <div className="row d-flex justify-content-center input-padding-24 mt-1">
            <Footer />
          </div>
          <div className="sc-contextual-information-header text-align-center">
            <span
              className="verification-resend-code link"
              onClick={() => goToFaq(ANCHOR_NEED_HELP)}
            >
              {t("common.need_help")}
            </span>
          </div>
        </div>
      </form>
    </>
  );
}
